@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Alkatra";
    src: url("../public/fonts/Alkatra/Alkatra-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Prototype";
    src: url("../public/fonts/prototype/Prototype.ttf");
}

@font-face {
    font-family: "Syne";
    src: url("../public/fonts/Syne/Syne-VariableFont_wght.ttf");
}

@layer utilities {
    @variants responsive {

        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}

@layer components {
    .screen-margin {
        @apply mx-7 md:mx-14 lg:mx-28 xl:mx-56;
    }
    .screen-padding {
        @apply px-7 md:px-14 lg:px-28 xl:px-56;
    }
    .logo-image {
        @apply cursor-pointer w-[180px] md:w-[230px] lg:w-[280px] xl:w-[380px] 2xl:w-[430px] 3xl:w-[480px] 4xl:w-[530px];
    }
    .letstalk-sm {
        @apply flex items-center justify-center font-Alkatra w-32 h-10 bg-primary-gradient text-white text-sm text-center font-semibold rounded-lg shadow-lg;
    }

    .letstalk-md {
        @apply flex items-center justify-center font-Alkatra w-36 h-12 bg-primary-gradient text-white text-base text-center font-semibold rounded-lg shadow-lg;
    }

    .letstalk-lg {
        @apply flex items-center justify-center font-Alkatra w-40 h-14 bg-primary-gradient text-white text-lg text-center font-semibold rounded-lg shadow-lg;
    }

    .letstalk-xl {
        @apply flex items-center justify-center font-Alkatra w-44 h-16 bg-primary-gradient text-white text-xl text-center font-semibold rounded-lg shadow-lg;
    }

    .navigation-md {
        @apply font-Alkatra font-bold text-[12px] cursor-pointer transition-all before:w-0 before:pt-px before:h-0.5 before:rounded-full before:bg-primary before:absolute before:left-0 before:top-[100%] before:transition-all before:duration-300 hover:before:w-full;
    }

    .navigation-lg {
        @apply font-Alkatra font-bold text-sm cursor-pointer transition-all before:w-0 before:pt-px before:h-0.5 before:rounded-full before:bg-primary before:absolute before:left-0 before:top-[100%] before:transition-all before:duration-300 hover:before:w-full;
    }

    .navigation-xl {
        @apply font-Alkatra font-bold text-base cursor-pointer transition-all before:w-0 before:pt-px before:h-0.5 before:rounded-full before:bg-primary before:absolute before:left-0 before:top-[100%] before:transition-all before:duration-300 hover:before:w-full;
    }

    .navigation-2xl {
        @apply font-Alkatra font-bold text-lg cursor-pointer transition-all before:w-0 before:pt-px before:h-0.5 before:rounded-full before:bg-primary before:absolute before:left-0 before:top-[100%] before:transition-all before:duration-300 hover:before:w-full;
    }

    .navigation-3xl {
        @apply font-Alkatra font-bold text-xl cursor-pointer transition-all before:w-0 before:pt-px before:h-0.5 before:rounded-full before:bg-primary before:absolute before:left-0 before:top-[100%] before:transition-all before:duration-300 hover:before:w-full;
    }

    .navigation-4xl {
        @apply font-Alkatra font-bold text-2xl cursor-pointer transition-all before:w-0 before:pt-px before:h-0.5 before:rounded-full before:bg-primary before:absolute before:left-0 before:top-[100%] before:transition-all before:duration-300 hover:before:w-full;
    }

    .head-sm {
        @apply font-Alkatra font-bold text-2xl text-left text-gray-300;
    }

    .head-md {
        @apply font-Alkatra font-bold text-4xl text-left text-gray-300;
    }

    .head-lg {
        @apply font-Alkatra font-bold text-6xl text-left text-gray-300;
    }

    .head-xl {
        @apply font-Alkatra font-bold text-7xl text-left text-gray-300;
    }

    .head-2xl {
        @apply font-Alkatra font-bold text-8xl text-left text-gray-300;
    }

    .head-3xl {
        @apply font-Alkatra font-bold text-9xl text-left text-gray-300;
    }

    .head-4xl {
        @apply font-Alkatra font-bold text-9xl text-left text-gray-300;
    }

    .subhead-sm {
        @apply font-Alkatra font-normal text-base text-left text-gray-300;
    }

    .subhead-md {
        @apply font-Alkatra font-normal text-2xl text-left text-gray-300
    }

    .subhead-lg {
        @apply font-Alkatra font-normal text-3xl text-left text-gray-300;
    }

    .subhead-xl {
        @apply font-Alkatra font-normal text-4xl text-left text-gray-300;
    }

    .subhead-2xl {
        @apply font-Alkatra font-normal text-5xl text-left text-gray-300;
    }

    .subhead-3xl {
        @apply font-Alkatra font-normal text-6xl text-left text-gray-300;
    }

    .subhead-4xl {
        @apply font-Alkatra font-normal text-7xl text-center text-gray-300;
    }

    .primary-head-sm {
        @apply font-Alkatra text-2xl font-bold;
    }

    .primary-head-md {
        @apply font-Alkatra text-3xl font-bold;
    }

    .primary-head-lg {
        @apply font-Alkatra text-4xl font-bold;
    }

    .primary-head-xl {
        @apply font-Alkatra text-5xl font-bold;
    }

    .primary-head-2xl {
        @apply font-Alkatra text-6xl font-bold;
    }

    .primary-head-3xl {
        @apply font-Alkatra text-7xl font-bold;
    }

    .primary-head-4xl {
        @apply font-Alkatra text-8xl font-bold;
    }

    .p-sm {
        @apply font-Alkatra text-lg leading-[2.5rem] text-left font-medium;
    }

    .p-md {
        @apply font-Alkatra text-xl text-left leading-[2.75rem] font-medium;
    }

    .p-lg {
        @apply font-Alkatra text-2xl leading-[3rem] text-left font-medium;
    }

    .p-xl {
        @apply font-Alkatra text-4xl leading-[3.5rem] text-left font-medium;
    }

    .p-2xl {
        @apply font-Alkatra text-5xl leading-[4rem] text-left font-medium;
    }

    .p-3xl {
        @apply font-Alkatra text-6xl leading-[5rem] text-left font-medium;
    }

    .p-4xl {
        @apply font-Alkatra text-7xl leading-[6rem] text-left font-medium;
    }

    .span-sm {
        @apply font-Alkatra text-sm text-left font-medium;
    }

    .span-md {
        @apply font-Alkatra text-base text-left font-medium;
    }

    .span-lg {
        @apply font-Alkatra text-lg text-left font-medium;
    }

    .span-xl {
        @apply font-Alkatra text-xl text-left font-medium;
    }

    .span-2xl {
        @apply font-Alkatra text-4xl text-left font-medium;
    }

    .span-3xl {
        @apply font-Alkatra text-5xl text-left font-medium;
    }

    .span-4xl {
        @apply font-Alkatra text-6xl text-left font-medium;
    }

    .primary-gradient-text {
        @apply text-transparent bg-primary-gradient bg-clip-text
    }

    .link-sm {
        @apply font-Alkatra font-normal text-sm cursor-pointer;
    }

    .link-md {
        @apply font-Alkatra font-normal text-base cursor-pointer;
    }

    .link-lg {
        @apply font-Alkatra font-normal text-lg cursor-pointer;
    }

    .link-xl {
        @apply font-Alkatra font-normal text-2xl cursor-pointer;
    }

    .link-2xl {
        @apply font-Alkatra font-normal text-4xl cursor-pointer;
    }

    .link-3xl {
        @apply font-Alkatra font-normal text-5xl cursor-pointer;
    }

    .link-4xl {
        @apply font-Alkatra font-normal text-6xl cursor-pointer;
    }

    .development-process {
        @apply w-[300px] h-[112px] md:w-[350px] md:h-[131px] lg:w-[400px] lg:h-[150px] xl:w-[400px] xl:h-[150px] 2xl:w-[400px] 2xl:h-[150px] 3xl:w-[400px] 3xl:h-[150px] 4xl:w-[400px] 4xl:h-[150px];
    }

    .line-style {
        @apply lg:before:w-[120px] lg:before:h-[1px] lg:before:border-t-[1px] lg:before:border-dashed lg:before:border-secondary lg:before:absolute lg:before:left-[140px] lg:before:top-[50px];
    }

}